@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  background-color: black;
  color: #f8fafc;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.app-container {
  position: relative;
}

.snap-y {
  scroll-snap-type: y mandatory;
}

.snap-start {
  scroll-snap-align: start;
}

.section-container {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.section-container.fadeIn {
  opacity: 1;
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  color: #f8fafc;
}

.scroll-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #f8fafc;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pill {
  width: 1.5rem;
  height: 2.5rem;
  border: 2px solid #f8fafc;
  border-radius: 1rem;
  position: relative;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #f8fafc;
  border-radius: 50%;
  position: absolute;
  animation: moveDot 2s infinite;
}

@keyframes moveDot {
  0%,
  100% {
    top: 10%;
  }
  50% {
    top: 70%;
  }
}

.italic-link {
  font-style: italic;
  color: #f8fafc;
  text-decoration: none;
  position: relative;
}

.italic-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #f8fafc;
  transition: width 0.4s ease;
}

.italic-link:hover::after {
  width: 100%;
  left: 0;
  background: #f8fafc;
}

.custom-pill-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 2px solid #f8fafc;
  color: #f8fafc;
  background-color: transparent;
  border-radius: 9999px;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.custom-pill-button:hover {
  background-color: #f8fafc;
  color: black;
}

.footer {
  text-align: center;
  padding: 1rem 0;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.footer-link {
  position: relative;
  color: #f8fafc;
  text-decoration: none;
}

.footer-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #f8fafc;
  transition: width 0.4s ease;
}

.footer-link:hover::after {
  width: 100%;
  left: 0;
  background: #f8fafc;
}

.logos-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.logo {
  opacity: 0.8;
}

.logo1 {
  width: 100px;
  height: 100px;
}

.logo2 {
  width: 100px;
  height: 100px;
}

.logo3 {
  width: 100px;
  height: 100px;
}

.logo4 {
  width: 100px;
  height: 100px;
}

.logo5 {
  width: 100px;
  height: 100px;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 1.5rem;
  }

  .section-container h1 {
    font-size: 4rem;
  }

  .section-container .title {
    white-space: pre-line;
  }

  .logos-container {
    flex-direction: column;
    align-items: center;
  }

  .scroll-indicator {
    bottom: 160px; 
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
